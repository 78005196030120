import React, { useEffect, useState } from "react";
// Components
import TopBarLayout from '../Layout/Topbar/topBar';
import Button from '../../components/Button/Button';
import Spinner from "components/Spinner/Spinner";
// Tools
import styled from "styled-components";
import theme from "theme";
// Utils
import { navigate } from "gatsby";
import Icon from "utils/icon";
// i18n
import { FormattedMessage } from "react-intl";
import globalMessages from "containers/messages"
// Redux
import { useSelector, useDispatch } from "react-redux";
// Actions
import { updateUserAction } from "actions/user";
import messages from "./messages";

const Container = styled.div`
  height: 100vh;
  width: 100%;
`;

const Return = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.darkgray};

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const ProfileContent = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: 32px;
  padding: 16px 32px;

  @media(max-width: 900px) {
    flex-flow: column-reverse;
    padding: 16px;
  }
`;

const EditProfileContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 32px;
  width: 50%;

  @media(max-width: 900px) {
    width: 100%;
  }
`;

const Infos = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const WrapperInfos = styled.div`

`;

const Form = styled.form`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const Field = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${theme.darkgray};
  margin-bottom: 8px;
`;

const Description = styled.div`
  color: ${theme.mediumgray};
`;

const InputStyled = styled.input`
  border: 1px solid ${theme.ui.grey};
  padding: 8px;
  border-radius: 5px;
`;

const Label = styled.div`
  color: ${theme.darkgray};
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledButton = styled.button`
  background: ${theme.white};
  border: none;
  color: ${theme.ui.blue};
  padding: 8px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const Tips = styled.div`
  display: flex;
  flex-flow: column;
  gap:16px;
  width: 40%;
  height: 100%;

  @media(max-width: 900px) {
    width: 100%;
  }
`;

const TipToComplete = styled.div`
  background-color: ${theme.lightorange};
  border: 1px solid ${theme.orange};
  padding: 16px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-flow: row;
  gap: 16px;
  align-items: center;
`;

const TipCompleted = styled.div`
  background-color: ${theme.specific.green.light};
  border: 1px solid ${theme.green};
  padding: 16px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-flow: row;
  gap: 16px;
  align-items: center;
`;

const ButtonEdit = styled.div`
  @media(max-width: 767px) {
    display: none;
  }
  `;

const IconDiv = styled.div`
  border: 1px solid ${theme.ui.grey};
  padding: 8px;
  border-radius: 5px;

  &:hover {
    background-color: ${theme.darkwhite};
    cursor: pointer;
    transition: all .3s;
  }
  @media(min-width: 768px) {
    display: none;
  }
`;

const InfosTip = styled.div`
`;

const StyledIconToComplete = styled.div`
  background-color: ${theme.orange};
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 12px;
`;

const StyledIconCompleted = styled.div`
  background-color: ${theme.green};
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 12px;
`;

const ErrorMessages = styled.div`
  color: ${theme.red};
  font-size: 14px;
`;

const ProfilePage = () => {

  const [userFront, setUserFront] = useState(null);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [change, setChange] = useState(false);
  const [error, setError] = useState(false);

  const { fetchingUpdateUser, failedUpdateUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const updateUser = (data, pastMail) => dispatch(updateUserAction(data, pastMail));

  useEffect(() => {
    resetFormData();
  }, [userFront])

  useEffect(() => {
    if (!fetchingUpdateUser) {
      setEdit(false);
    }
    try {
      const userFront = typeof window !== 'undefined' && JSON.parse(localStorage?.getItem("userFront"));
      setUserFront(userFront);
    }
    catch (e) {
      console.error("not good local storage");
    }
  }, [fetchingUpdateUser])

  const handleChange = (e, type) => {
    setFormData({
      ...formData,
      [type]: e.target.value,
    })
    setChange(true);
  }

  const cancelEdit = () => {
    setEdit(false);
    resetFormData();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (change && checkIfNotNull()) {
      updateUser(formData, userFront?.mail);
      setChange(false);
      setError(false);
    } else {
      setEdit(false);
    }
  }

  const checkIfNotNull = () => {
    if (formData.firstname !== "" && formData.lastname !== "" && formData.mail !== "" && formData.phone !== "" && formData.organization !== "") {
      return true;
    } else {
      resetFormData()
      setError(true);
      return false;
    }
  }

  const resetFormData = () => {
    setFormData({
      firstname: userFront?.firstname || "",
      lastname: userFront?.lastname || "",
      mail: userFront?.mail || "",
      phone: userFront?.phone || "",
      organization: userFront?.organization || "",
    })
  }

  return (
    <Container>
      <TopBarLayout />
      <Return onClick={() => navigate("/")}>
        <Icon icon="LeftArrow" size="sm" />
        <FormattedMessage {...globalMessages.return} />
      </Return>
      <ProfileContent>
        <EditProfileContainer>
          <Infos>
            <WrapperInfos>
              <Title>
                {
                  userFront?.lastname && userFront?.firstname ?
                  `${userFront?.lastname} ${userFront?.firstname}` :
                  `${userFront?.username}`
                }
              </Title>
              <Description>
                {userFront?.mail}
              </Description>
            </WrapperInfos>
            {!edit &&
              <ButtonEdit>
                <Button onClick={() => setEdit(true)}><FormattedMessage {...messages.edit_profile} /></Button>
              </ButtonEdit>
            }
            <IconDiv>
              <Icon icon="Edit" size="sm" />
            </IconDiv>
          </Infos>
          <Form onSubmit={handleSubmit}>
            <Field>
              <Label><FormattedMessage {...messages.lastname} /></Label>
              <InputStyled disabled={!edit} value={formData?.lastname} onChange={(e) => handleChange(e, "lastname")}/>
            </Field>
            <Field>
              <Label><FormattedMessage {...messages.firstname} /></Label>
              <InputStyled disabled={!edit} value={formData?.firstname} onChange={(e) => handleChange(e, "firstname")}/>
            </Field>
            <Field>
              <Label><FormattedMessage {...messages.mail} /></Label>
              <InputStyled disabled={!edit} value={formData?.mail} onChange={(e) => handleChange(e, "mail")}/>
            </Field>
            <Field>
              <Label><FormattedMessage {...messages.phone} /></Label>
              <InputStyled disabled={!edit} value={formData?.phone} onChange={(e) => handleChange(e, "phone")}/>
            </Field>
            <Field>
              <Label><FormattedMessage {...messages.organization} /></Label>
              <InputStyled disabled={!edit} value={formData?.organization} onChange={(e) => handleChange(e, "organization")}/>
            </Field>
            {error &&
              <ErrorMessages>
                <FormattedMessage {...messages.error} />
              </ErrorMessages>
            }
            {edit &&
              <Buttons>
                <StyledButton onClick={() => cancelEdit()}>{!fetchingUpdateUser && <FormattedMessage {...messages.cancel} />}</StyledButton>
                <Button disable={fetchingUpdateUser} type="submit">
                  { fetchingUpdateUser ? 
                    <Spinner invert><Icon icon="Refresh" size="sm"/></Spinner>
                  :
                    <FormattedMessage {...messages.confirm} /> 
                  }
                </Button>
              </Buttons>
            }
          </Form>
        </EditProfileContainer>
          <Tips>
        {userFront?.lastname === null || userFront?.firstname === null ?
          <TipToComplete>
            <StyledIconToComplete>
              <Icon icon="Alert" size="lg" />
            </StyledIconToComplete>
            <InfosTip>
              <Title><FormattedMessage {...messages.complete_profile} /></Title>
              <Description><FormattedMessage {...messages.complete_profil_reminder} /></Description>
            </InfosTip>
          </TipToComplete>
        :
          <TipCompleted>
            <StyledIconCompleted>
              <Icon icon="Check" size="lg" />
            </StyledIconCompleted>
            <InfosTip>
              <Title><FormattedMessage {...messages.profile_complete} /></Title>
              <Description><FormattedMessage {...messages.profile_complete_reminder} /></Description>
            </InfosTip>
          </TipCompleted>
        }
          </Tips>
      </ProfileContent>
    </Container>
  )
}

export default ProfilePage
